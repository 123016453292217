/**
 *
 * Youtube Player Modal
 * Author: jerrydomm@gmail.com
 */

let videoModal = (function () {
    //Create DOM

    let Videoboxed = $('<div class="Video-box" data-modal="modal"><span class= "video-close">x</span ><div class="Video-data"></div><a class="BtnStd BtnStd-primary videoLink"></a></div>');
    let videoDomOverlay = $('<div>', {
        class: 'OverlayModal'
    });

    let containerDom = $('main');

    if ($('.videoSingle').length > 0) {
        containerDom.append(Videoboxed, videoDomOverlay);
    }

    //DOM options
    let options = {
        videoTarget: 'videotarget',
        videoMain: '.Video-box',
        videoContainer: '.Video-data',
        videoIdAttribute: 'videoId',
        videoCloseSelector: '.video-close',
        videoPlayIcon: 'videoplayicon'
    };

    //API
    function youTubeAPI() {
        let tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    //Init player
    let playerEl = document.querySelectorAll('[data-videotarget]');

    youTubeAPI();

    //player YT constructor

    class YouTubePlayer {
        constructor(sourceId, componentContainer) {
            this.id = 'idIframe_' + sourceId;
            this.sourceId = sourceId;
            this.playerObj = null;
            this.componentContainer = componentContainer;
            let self = this;
            let videoDomElement = $('<div>', {
                id: self.id
            });

            self.componentContainer.append(videoDomElement);

            self.playerObj = new YT.Player(self.id, {
                width: '100%',
                height: '100%',
                videoId: self.sourceId,
                playerVars: {
                    'modestbranding': 1,
                    'rel': 0,
                    'showinfo': 0,
                    'wmode': 'opaque',
                    'iv_load_policy': 3,
                    'autoplay': 1
                }
            });

        }
    }

    function VideoMp4(source, videoContainer){

        let videoDOM = $('<video class="video_local" controls><source src="'+ source +'" type="video/mp4"></video>');
        videoContainer.append(videoDOM);
    }


    for (let i = 0; i < playerEl.length; i++) {
        let playerAttr = $(playerEl[i]).attr('data-' + options.videoIdAttribute + ''),
            videoContainer = $(options.videoContainer);

        if($(playerEl[i]).data(options.videoPlayIcon) === "icon"){
            $(playerEl[i]).append('<span class="icon-play"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 69 69"><g fill="none" fill-rule="evenodd" transform="translate(1 1)"><circle cx="33.5" cy="33.5" r="33.5" stroke="#FFF" stroke-opacity=".684" stroke-width="2"/><path fill="#FFF" d="M29 18L45 34 29 50z"/></g></svg></span>');
        }

        playerEl[i].addEventListener("click", function () {

            if($(playerEl[i]).data(options.videoTarget) === "videoYT"){
                let thisPlayer = new YouTubePlayer(playerAttr, videoContainer);
            }

            if ($(playerEl[i]).data(options.videoTarget) === "videoLocal"){
                let source = playerAttr.split('public/').pop();
                let videoDOM = $('<video class="video_local" controls autoplay><source src="'+ source +'" type="video/mp4"></video>');
                videoContainer.append(videoDOM);
            }

            $(options.videoMain).addClass("showme");


        });

    }


    // destroy player
    let playerClose = $(options.videoCloseSelector);
    playerClose.on('click', destroyVideo);

    function destroyVideo() {
        $(options.videoMain).removeClass('showme');
        let next = $(this).next().empty();
        containerDom.remove('.OverlayModal');
    }

    //MP4
    // let playerMp4 = document.querySelectorAll('[data-videotarget="videoLocal"]');

})();
