const { at } = require("lodash");

window.Home = new (function(){
    var that = this;


    this.sldrsHome = function(){
        let sldrProd = $('.seo-sldr');

        sldrOpt = {
            dots: true,
            arrows: false,
            infinite: false,
            slidesToShow: 1,
            responsive: [{
                breakpoint: 99999,
                settings: "unslick"
            },
            {
                breakpoint: 992,
                settings: {
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1,
                }
            }]
        }

        sldrProd.each((index,elem) =>{
            $(elem).slick(sldrOpt);
            $(window).on('resize', function () {
                if (!$(elem).hasClass('slick-initialized')) {
                    return $(elem).slick(sldrOpt);
                }
            });
        });
    } 

    this.videosMq = function(){
        let video =  document.querySelector('video'),
            breackpoint = 768; 

        //add source to video tag
        function addSourceToVideo(element, src) {
            var source = document.createElement('source');
            source.src = src;
            source.type = 'video/mp4';
            element.appendChild(source);
            
        }
        
        
        let mql = window.matchMedia("(min-width:" + breackpoint + "px)");

        function mediaqueryresponse(mql) {
            if (mql.matches) {
                $('video source').remove();
                addSourceToVideo(video, video.dataset.dsk);
               
             } else {
                $('video source').remove();
                addSourceToVideo(video, video.dataset.mb);
             }
     
        }

        mediaqueryresponse(mql)

        mql.addEventListener('change', mediaqueryresponse);
        
    }

    this.init = function () {
        that.sldrsHome();
        //that.videosMq();
    };

})(); 