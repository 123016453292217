$(function(){
    let onScroll = function(){
        $(window).scroll(function () {
            if ($(window).scrollTop() > 70)
                $('header').addClass('stick')
            else
                $('header').removeClass('stick')
        })
    }

    let putPdftxt = function(){
        $(".cv").each((index,elem) =>{
            let $elm = $(elem);
            $elm.on('change', () =>{
                $elm.siblings('div.pdfName').html('Tu archivo es: <strong>'+ $elm.get(0).files[0].name + '</strong>');
            });
        });
    }

    let queoteValidation = function(){

        $(".BeeForms").each((index,elem) =>{
            let $elm = $(elem);
            $elm.on('submit', function(e){
                e.preventDefault();

                $.ajax({
                    url:$elm.attr('action'),
                    method:$elm.attr('method'),
                    data:new FormData(this),
                    processData:false,
                    dataType:'json',
                    contentType:false,
                    beforeSend:function(){
                        $elm.find('span.error-text').text('');
                    },
                    success:function(data){
                        
                        if(data.status == 0){
                            $.each(data.error, function(prefix, val){
                                $elm.find('span.'+prefix+'_error').text(val[0]);
                            });
                        }else{
                            $(".BeeForms")[0].reset();
                            
                            window.location.href = '/contacto/' + data.success + "-enviado";
                        }
                    }
                });
            });
        });
    }

    let menu = function(){
        $('.hamburger').on('click', function(){
            $('body').toggleClass('noScroll');
        })
    }

    let formTabs = function(){
        $('.formBox').simpleTab({
            container: '.jdTab',
            attribute: 'href', 
            effect: "fade"
        });
    }

    onScroll();
    putPdftxt();
    queoteValidation();
    menu();
    formTabs();
    $('.rotate').rotatetext();

});