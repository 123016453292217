/*
*   Simple tabs
*   jerrydomm
*   
    markup:
    <ul class="jdtab">
        <li><a href="#tab1">TAB 1</a></li>
    </ul>
    <div class="namespace-container">
        <div class="jdtab_content" id="tab1">
            {{ contenido }}
        </div>
    </div>
    js:
    $('.jdtab li').simpleTab({
        content: '.content',
        attribute: 'href', //options:href o rel
        effect: "fade"     //options:fade o show
    });

*/

jQuery.fn.extend({  
    simpleTab: function(options) {
    	defaults = {
            container: '',		
    		handler: '.jdTab>li',
    		content: '.jdTab_content',
            attribute: 'href',
            effect: "fade"
    	}
        var self = $(this);
    	var options = $.extend({}, defaults, options);
        
            $(self,'options.container').each(function(){
                var clicker = $(this).find(options.handler),
                    content = $(this).find(options.content);   

                content.hide();
                clicker.first().addClass("current");
                content.first().show();
               

                clicker.on('click',function(){
                    clicker.removeClass("current");
                    $(this).addClass("current"); 
                    content.hide();
                    if(options.attribute == "rel" ){
                        var activeTab = $(this).attr(options.attribute);
                    } else{
                        var activeTab = $(this).find('a').attr(options.attribute);
                        
                    }
                    if(options.effect == "fade" ){
                        $(activeTab).fadeIn();
                    } else {
                        $(activeTab).show();
                    } 
                    return false;
                });
            })

    }  
});