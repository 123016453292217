/**
 *
 * Youtube Player
 * Author: jerrydomm@gmail.com
 */

 let reel = (function () {
    //Create DOM

    let Videoboxed = $('<div class="VideoReel-box" data-modal="modal"><span class= "reel-close">x</span ><div class="Video-data"></div><a class="BtnStd BtnStd-primary videoLink"></a></div>');


    let containerDom = $('.bee-reel');

    if ($('.videoReel').length > 0) {
        containerDom.append(Videoboxed);
    }

    //DOM options
    let options = {
        videoTarget: 'videotarget',
        videoMain: '.VideoReel-box',
        videoContainer: '.Video-data',
        videoIdAttribute: 'videoId',
        videoCloseSelector: '.reel-close',
        videoPlayIcon: 'videoplayicon'
    };

    //API
    function youTubeAPI() {
        let tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    //Init player
    let playerEl = document.querySelector('.videoReel');

    youTubeAPI();

    class YouTubePlayer {
        constructor(sourceId, componentContainer) {
            this.id = 'idIframe_' + sourceId;
            this.sourceId = sourceId;
            this.playerObj = null;
            this.componentContainer = componentContainer;
            let self = this;
            let videoDomElement = $('<div>', {
                id: self.id
            });

            self.componentContainer.append(videoDomElement);

            self.playerObj = new YT.Player(self.id, {
                width: '100%',
                height: '100%',
                videoId: self.sourceId,
                playerVars: {
                    'modestbranding': 1,
                    'rel': 0,
                    'showinfo': 0,
                    'wmode': 'opaque',
                    'iv_load_policy': 3,
                    'autoplay': 1
                },
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange
                }
            });

            function onPlayerReady() {
                setTimeout(playVideo, 1200);
            }

            function playVideo(){
                self.playerObj.playVideo();
            }

            function onPlayerStateChange(event) {
                if (event.data == YT.PlayerState.ENDED) {               
                    self.playerObj.seekTo(0);
                    self.playerObj.stopVideo();
                }
            }
        }
    }

    let playerAttr = $(playerEl).attr('data-' + options.videoIdAttribute + ''),
        videoContainer = $(options.videoContainer);

    if($(playerEl).length>0){
        playerEl.addEventListener('click',function (e) {
            if($(playerEl).data(options.videoTarget) === "videoYT"){
                let thisPlayer = new YouTubePlayer(playerAttr, videoContainer);
            }
            e.preventDefault();     //evitar el eventos del enlace normal
            $('body,html').stop(true,true).animate({
                scrollTop: $('.bee-reel').offset().top
            },1000);
            //$(options.videoMain).addClass("showme");
            setTimeout(openVideo, 1000);
            function openVideo(){
                $(options.videoMain).slideDown();
            }
        });
    }

    // destroy player
    let playerClose = $(options.videoCloseSelector);
    playerClose.on('click', destroyVideo);

    function destroyVideo() {
        //$(options.videoMain).removeClass('showme');
        $(options.videoMain).slideUp();
        let next = $(this).next().empty();
    }


})();
