const { transform } = require("lodash");

(function () {
    function easeOutQuad(t) {
        return t * (2 - t)
    }
    let findMe = document.getElementsByClassName('elParallax');
    // helper functions
    const MathUtils = {
        // map number x from range [a, b] to [c, d]
        map: (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c,
        // linear interpolation
        lerp: (a, b, n) => (1 - n) * a + n * b,
        // Random float
        getRandomFloat: (min, max) => (Math.random() * (max - min) + min).toFixed(2)
    };
    document.onscroll = function () {
        //move element
        elmsParallax = document.querySelectorAll('.do-parallax');
        windowW = window.innerWidth;

        for (let i = 0; i < elmsParallax.length; i++) {
            let speed = elmsParallax[i].getAttribute('data-speed'),
                scale = elmsParallax[i].getAttribute('data-scale'),
                mobile = elmsParallax[i].getAttribute('data-mobile'),
                direction = elmsParallax[i].getAttribute('data-direction'),
                overflow = elmsParallax[i].getAttribute('data-overflow');

            doParallax(direction, speed, elmsParallax[i], mobile, scale, overflow);
        }

    };

    function doParallax(direction, speed, element, mobile, scale, overflow) {

        // Check if element exists
        if ((typeof (element) == 'undefined' || element == null) || (typeof (speed) == 'undefined' || speed == null)) {
            return false;
        }

        if (window.innerWidth < 768) {
            if (typeof (mobile) == 'undefined' || mobile == null || mobile == 0) {
                // remove styles on mobile to not overlap text
                element.removeAttribute('style');
                return false;
            }
        }

        if (typeof (scale) == 'undefined' || scale == null) {
            // default direction
            // element.dataset.scale = '1';
        }

        if (typeof (overflow) == 'undefined' || overflow == null) {
            // default direction
            element.parentElement.classList.remove("do-overflow");
        } else {
            element.parentElement.classList.add("do-overflow");
        }

        // Only do parallax if element is visible on screen
        // to have better performance
        if (checkVisible(element)) {
            
            let scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop,
                bodyRect = document.body.getBoundingClientRect(),
                elemRect = element.getBoundingClientRect(),
                offset = elemRect.top - bodyRect.top,
                offsetTop = scrollTop + elemRect.top,
                intersection = Math.floor((offset - scrollTop) / element.getAttribute('data-speed')),
                toValue = 1.2,
                fromValue = 1,
                scaleVal;

                if (direction === "up"){
                    scaleVal = MathUtils.map(offsetTop - scrollTop, window.innerHeight, -1 * element.height, fromValue, toValue); 
                } else if(direction === "down"){
                    scaleVal = MathUtils.map(offsetTop - scrollTop, window.innerHeight, -1 * element.height, toValue, fromValue);
                }

                let onScale = Math.max(Math.min(scaleVal, toValue), fromValue);
               
            //element.style.transform = 'translateX(' + Math.floor((offset - scrollTop) / element.getAttribute('data-speed')) + 'px)';
            if (element.getAttribute('data-scaleonscroll') && element.getAttribute('data-direction'))
                element.style.transform = 'scale(' + onScale + ')';
            else if (element.getAttribute('data-scale'))
                element.style.transform = 'translateY(' + intersection + 'px) scale(' + element.getAttribute('data-scale') + ')';
            else
                element.style.transform = 'translateY(' + intersection + 'px)';
        }

    }

    function checkVisible(elm) {
        var rect = elm.getBoundingClientRect();
        var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect.bottom <= 100 || rect.top - viewHeight >= 100);
    }
})()
